import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, ListItemText } from '@mui/material';
import roles from '../../hooks/Roles';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const SettingsMenu = ({ onClick, selectedRegion }) => { 
  const {user:{userRights}} = useUser();
  return (
    <div>
      {userRights >= roles['PRODUCTION'] && (
        <MenuItem
          component={Link}
          onClick={onClick}
          to="/models"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}
        >
          <ListItemText>{GetTextByRegion("Models",selectedRegion)}</ListItemText>
        </MenuItem>
      )}
     
      {userRights >= roles['ADMIN'] && (
        <MenuItem
          component={Link}
          onClick={onClick}
          to="/users"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}
        >
          <ListItemText >{GetTextByRegion("Users",selectedRegion)}</ListItemText>
        </MenuItem>
      )}
       {userRights >= roles['MODERATOR'] && selectedRegion === "USA" && (
        <MenuItem
          component={Link}
          onClick={onClick}
          to="/packinglist"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}
        >
          <ListItemText>{GetTextByRegion("Packing List",selectedRegion)}</ListItemText>
        </MenuItem>
      )}
      {/* {userRights >= roles['MODERATOR'] && selectedRegion === "USA" && (
        <MenuItem
          component={Link}
          onClick={onClick}
          to="/incomingchecklist"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}
        >
          <ListItemText>{GetTextByRegion("Incoming Checklist",selectedRegion)}</ListItemText>
        </MenuItem>
      )} */}
      {userRights >= roles['ADMIN'] && (selectedRegion === "USA") && (
        <MenuItem
          component={Link}
          onClick={onClick}
          to="/customers"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}
        >
          <ListItemText>{GetTextByRegion("Customers",selectedRegion)}</ListItemText>
        </MenuItem>
      )}
    </div>
  );
};

export default SettingsMenu;
