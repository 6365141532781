import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Box, FormControl, InputLabel, Select, MenuItem, FormHelperText} from '@mui/material';
import TechnicianDropdown from './TechnicianDropdown';
import { useTheme } from '@mui/material/styles';
import ErrorDialog from '../ErrorDialog';
import { useUser } from '../../hooks/UserContext';
import { GetTextByRegion } from '../../hooks/Translation';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const EditEventForm = ({ event, onClose, onEditSuccess }) => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    title: '',
    customer: '',
    serial: '',
    model: [],
    address: '',
    serviceType: '',
    description: '',
    category: '',
    technicians: '',
    addTechnicians: '',
  });
  const [models, setModels] = useState([]);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState('');
  const { selectedRegion } = useUser();

  const toLocalDateString = (date) => {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (event) {
      setFormData({
        startDate: toLocalDateString(event.start),
        endDate: toLocalDateString(event.end),
        startTime: event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }),
        endTime: event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }),
        title: event.title,
        customer: event.customer,
        serial: event.serial,
        model: event.model,
        address: event.address,
        serviceType: event.serviceType,
        description: event.description,
        technicians: event.technicians,
        addTechnicians: event.addTechnicians,
      });
    }
  }, [event]);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response1 = await axios.get(`${backendString}/dropdown?selectedRegion=${selectedRegion}&type=machine`);
        const response2 = await axios.get(`${backendString}/dropdown?selectedRegion=${selectedRegion}&type=rack`);
        setModels([...response1.data, ...response2.data]); // Adjust based on your backend response structure
      } catch (error) {
        console.error('Error fetching machine models:', error);
        setError(error.response?.data?.error);
      }
    };
    fetchModels();
  }, [selectedRegion]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleModelChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      model: value,
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.startDate) newErrors.startDate = 'Start Date is required';
    if (!formData.endDate) newErrors.endDate = 'End Date is required';
    if (!formData.startTime) newErrors.startTime = 'Start Time is required';
    if (!formData.endTime) newErrors.endTime = 'End Time is required';
    if (!formData.title) newErrors.title = 'Title is required';
    if (!formData.customer) newErrors.customer = 'Customer is required';
    if (!formData.serial) newErrors.serial = 'Serial is required';
    if (formData.model.length === 0) newErrors.model = 'Model(s) is/are required';
    if (!formData.address) newErrors.address = 'Address is required';
    if (!formData.serviceType) newErrors.serviceType = 'Service Type is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()){
      return;
    }
    try {
      const updatedEvent = {
        startDate: formData.startDate,
        endDate: formData.endDate,
        startTime: formData.startTime,
        endTime: formData.endTime,
        title: formData.title,
        customer: formData.customer,
        serial: formData.serial,
        model: formData.model,
        address: formData.address,
        serviceType: formData.serviceType,
        description: formData.description,
        technicians: formData.technicians,
        addTechnicians: formData.addTechnicians,
      };

      let connectionString = '';
      connectionString = backendString + `/events/${event.id}`;

      const response = await axios.put(`${connectionString}/?selectedRegion=${selectedRegion}`, updatedEvent, {
        headers: {
        'Authorization': sessionStorage.getItem('token')
        }
    });  
      const formattedEvent = {
        id: response.data._id,
        title: response.data.title,
        start: new Date(response.data.startDate + "T" + response.data.startTime),
        end: new Date(response.data.endDate + "T" + response.data.endTime),
        customer: response.data.customer,
        serial: response.data.serial,
        model: response.data.model,
        address: response.data.address,
        serviceType: response.data.serviceType,
        description: response.data.description,
        technicians: response.data.technicians,
        addTechnicians: response.data.addTechnicians,
      };
  
      onEditSuccess(formattedEvent);
    } catch (error) {
      console.error('Error updating event:', error);
      setError(error.response?.data?.error);
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{GetTextByRegion("Edit Event",selectedRegion)}</DialogTitle>
      <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            width={300}
            mx="auto"
            mt={1}
          >
            <TextField
              label={GetTextByRegion("Start Date",selectedRegion)}
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
              required
              error={!!errors.startDate && !formData.startDate}
              helperText={formData.startDate ? '' : errors.startDate}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                style: { color: !formData.startDate && errors.startDate ? theme.palette.error.main : 'inherit' }
              }}
            />
            <TextField
              label={GetTextByRegion("End Date",selectedRegion)}
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
              required
              error={!!errors.endDate && !formData.endDate}
              helperText={formData.endDate ? '' : errors.endDate}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                style: { color: !formData.endDate && errors.endDate ? theme.palette.error.main : 'inherit' }
              }}
            />
            <TextField
              label={GetTextByRegion("Start Time",selectedRegion)}
              type="time"
              name="startTime"
              value={formData.startTime}
              onChange={handleInputChange}
              required
              error={!!errors.startTime && !formData.startTime}
              helperText={formData.startTime ? '' : errors.startTime}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                style: { color: !formData.startTime && errors.startTime ? theme.palette.error.main : 'inherit' }
              }}
            />
            <TextField
              label={GetTextByRegion("End Time",selectedRegion)}
              type="time"
              name="endTime"
              value={formData.endTime}
              onChange={handleInputChange}
              required
              error={!!errors.endTime && !formData.endTime}
              helperText={formData.endTime ? '' : errors.endTime}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                style: { color: !formData.endTime && errors.endTime ? theme.palette.error.main : 'inherit' }
              }}
            />
            <TextField
              label={GetTextByRegion("Title",selectedRegion)}
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              error={!!errors.title && !formData.title}
              helperText={formData.title ? '' : errors.title}
            />
            <TextField
              label={GetTextByRegion("Customer",selectedRegion)}
              name="customer"
              value={formData.customer}
              onChange={handleInputChange}
              required
              error={!!errors.customer && !formData.customer}
              helperText={formData.customer ? '' : errors.customer}
            />
            <TextField
              label={GetTextByRegion("Serial Number",selectedRegion)}
              variant="outlined"
              name="serial"
              value={formData.serial}
              onChange={handleInputChange}
              required
              error={!!errors.serial && !formData.serial}
              helperText={formData.serial ? '' : errors.serial}
            />
            <FormControl variant="outlined" required error={!!errors.model && formData.model.length === 0}>
              <InputLabel shrink htmlFor="model">
              {GetTextByRegion("Model",selectedRegion)}(s)
              </InputLabel>
              <Select
                label="Model(s)"
                name="model"
                value={formData.model}
                onChange={handleModelChange}
                multiple
                inputProps={{ id: 'model' }}
                renderValue={(selected) => selected.join(', ')}
              >
                {models
                  .slice() // Create a shallow copy of the array
                  .sort((a, b) => a.ModelName.localeCompare(b.ModelName)) // Sort alphabetically
                  .map((model) => (
                  <MenuItem key={model._id} value={model.ModelName}>
                    {model.ModelName}
                  </MenuItem>
                ))}
              </Select>
              {errors.model && formData.model.length === 0 && (
                <FormHelperText>{errors.model}</FormHelperText>
              )}
            </FormControl>
            <TextField
              label= {GetTextByRegion("Address",selectedRegion)}
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              required
              error={!!errors.address && !formData.address}
              helperText={formData.address ? '' : errors.address}
            />
            <FormControl variant="outlined" required error={!!errors.serviceType && !formData.serviceType}>
              <InputLabel shrink htmlFor="serviceType">
                {GetTextByRegion("Type of Service",selectedRegion)}
              </InputLabel>
              <Select
                label="Type of Service"
                name="serviceType"
                value={formData.serviceType}
                onChange={handleInputChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Type of Service' }}
              >
                <MenuItem value="PM 1 Year">{GetTextByRegion("PM 1 Year",selectedRegion)}</MenuItem>
                <MenuItem value="PM 3 Year">{GetTextByRegion("PM 3 Year",selectedRegion)}</MenuItem>
                <MenuItem value="Installation">{GetTextByRegion("Installation",selectedRegion)}</MenuItem>
                <MenuItem value="Software">{GetTextByRegion("Software",selectedRegion)}</MenuItem>
                <MenuItem value="Repair">{GetTextByRegion("Repair",selectedRegion)}</MenuItem>
                <MenuItem value="Other">{GetTextByRegion("Other",selectedRegion)}</MenuItem>
              </Select>
              {errors.serviceType && !formData.serviceType && (
                <FormHelperText>{errors.serviceType}</FormHelperText>
              )}
            </FormControl>
            <TextField
              label={GetTextByRegion("Description",selectedRegion)}
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              multiline
              rows={4}
            />
            <TechnicianDropdown 
              label={GetTextByRegion("Technicians",selectedRegion)} 
              name="technicians"
              formData={formData.technicians} 
              handleChange={handleInputChange} 
            />
            <TechnicianDropdown 
              label={GetTextByRegion("Additional Technicians",selectedRegion)}
              name="addTechnicians"
              formData={formData.addTechnicians} 
              handleChange={handleInputChange} 
            />
            <DialogActions>
              <Button variant="contained" onClick={onClose} color='secondary'>
                {GetTextByRegion("Cancel",selectedRegion)}
              </Button>
              <Button onClick={handleFormSubmit} variant="contained" color="primary">
                {GetTextByRegion("Save Changes",selectedRegion)}
              </Button>
            </DialogActions>
          </Box>
      </DialogContent>
    </Dialog>

    {/* Error Dialog */}
    <ErrorDialog
      open={!!error}
      onClose={handleCloseErrorDialog}
      errorMessage={error}
    />    
    </>
  );
};

export default EditEventForm;
