import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox, Grid } from '@mui/material';
import ErrorDialog from '../ErrorDialog';
import afterSaleColumnData from '../AfterSales/AfterSalesColumns';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import { format } from 'date-fns';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const AfterSalesForm = ({ openDialog, onClose, selectedItem = null, isEdit = false, onSave, tabRegion }) => {
  const defaultData = {
    applicationdate: format(new Date(), 'yyyy-MM-dd'),
    ordernumber: '',
    customername: '',
    machineID: '',
    machinemodel: '',
    materialname: '',
    materialnumber: '',
    requestedquantity: 0,
    chargestatus: '',
    requestreason: '',
    region: '',
    shippedquantity: 0,
    deliverynote: '',
    trackingnumber: '',
    dispatchdate: '',
    recyclestatus: false,
    returneddate: '',
    warehousereceiptnumber: '',
    remarks: ''
  }

  const { selectedRegion } = useUser();
  const [errorMessage, setErrorMessage] = useState('');
  const [openErrorDialog, setErrorDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(defaultData);

  useEffect(() => {
    if (isEdit && selectedItem) {
      setFormData(selectedItem);
    }
  }, [isEdit, selectedItem]);

  const handleCloseErrorDialog = () => {
    setErrorDialog(false);
    setErrorMessage('');
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.applicationdate) newErrors.applicationdate = GetTextByRegion('Application Date is required', selectedRegion);
    if (!formData.ordernumber) newErrors.ordernumber = GetTextByRegion('Order Number is required', selectedRegion);
    if (!formData.customername) newErrors.customername = GetTextByRegion('Customer Name is required', selectedRegion);
    if (!formData.materialname) newErrors.materialname = GetTextByRegion('Material Name is required', selectedRegion);
    if (!formData.requestedquantity || formData.requestedquantity <= 0) newErrors.requestedquantity = GetTextByRegion('Quantity must be greater than zero', selectedRegion);
    if (!formData.requestreason) newErrors.requestreason = GetTextByRegion('Request Reason is required', selectedRegion);
    if (!formData.region) newErrors.region = GetTextByRegion('Region is required', selectedRegion);
    if (!formData.shippedquantity || formData.shippedquantity <= 0) newErrors.shippedquantity = GetTextByRegion('Quantity must be greater than zero', selectedRegion);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate()) {
      return;
    }
    try {
      var response;
      if (isEdit) {
        response = await axios.put(`${backendString}/aftersales/${formData._id}?region=${tabRegion.tabRegion.region}`, formData, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          }
        });
      } else {
        response = await axios.post(`${backendString}/aftersales?region=${tabRegion.tabRegion.region}`, formData, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          }
        });
      }
      onSave(response.data);
      handleClose();
    } catch (error) {
      setErrorMessage(error.response?.data?.error || 'An error occurred');
      setErrorDialog(true);
    }
  };

  const handleClose = () => {
    setErrors({});
    setFormData(defaultData);
    onClose();
  }

  return (
    <div>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>{isEdit ? GetTextByRegion('Edit After Sales Record', selectedRegion) : GetTextByRegion('Add After Sales Record', selectedRegion)}</DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Box display="flex" flexDirection="column" gap={2} mt={1}>
            <Grid container spacing={2}>
              {['left', 'right'].map((position) => (
                <Grid item xs={12} sm={6} key={position}>
                  <Grid container spacing={2}>
                    {afterSaleColumnData
                      .filter(field => field.position === position)
                      .map((field, index) => (
                        <Grid item xs={12} key={field.name}>
                          {field.type === 'checkbox' ? (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={field.name}
                                  checked={!!formData[field.name]}
                                  onChange={handleInputChange}
                                />
                              }
                              label={GetTextByRegion(field.label, selectedRegion)}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '56px'
                              }}
                            />
                          ) : field.type === 'number' ? (
                            <TextField
                              key={index}
                              label={GetTextByRegion(field.label, selectedRegion)}
                              name={field.name}
                              type={"number"}
                              value={formData[field.name]}
                              onChange={handleInputChange}
                              required={field.required}
                              error={errors[field.name] && formData[field.name] <= 0}
                              helperText={formData[field.name] <= 0 ? errors[field.name] : ''}
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '100%' }}
                            />
                          ) : (
                            <TextField
                              key={index}
                              label={GetTextByRegion(field.label, selectedRegion)}
                              name={field.name}
                              type={field.type}
                              value={formData[field.name]}
                              onChange={handleInputChange}
                              required={field.required}
                              error={!!errors[field.name] && !formData[field.name]}
                              helperText={!formData[field.name] ? errors[field.name] : ''}
                              InputLabelProps={field.InputLabelProps}
                              sx={{
                                width: '100%',
                                ...(field.type === 'date' && {
                                  input: {
                                    color: !formData[field.name] && errors[field.name] ? theme => theme.palette.error.main : 'inherit',
                                  },
                                }),
                              }}
                            />
                          )}
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">{GetTextByRegion("Cancel", selectedRegion)}</Button>
          <Button onClick={handleSave} variant="contained" color="primary">{isEdit ? GetTextByRegion('Save', selectedRegion) : GetTextByRegion('Add', selectedRegion)}</Button>
        </DialogActions>
      </Dialog>
      <ErrorDialog open={openErrorDialog} onClose={handleCloseErrorDialog} errorMessage={errorMessage} />
    </div>
  );
};

export default AfterSalesForm;
