import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useUser } from '../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const IncomingShipmentView = () => {
    const [formData, setFormData] = useState({
        model: '',
        titles: [],
    });

    const [shipments, setShipments] = useState({});
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const { selectedRegion } = useUser();

    useEffect(() => {
        if (selectedRegion) {
            const fetchDropdownOptions = async () => {
                try {
                    const response = await axios.get(`${backendString}/dropdown`, {
                        params: { selectedRegion, type: 'machine' },
                    });
                    setDropdownOptions(response.data || []);
                } catch (error) {
                    console.error('Error fetching dropdown options:', error);
                }
            };
    
            fetchDropdownOptions();
        }
    }, [selectedRegion]);

    const handleChange = (field, titleIndex, value) => {
        setFormData((prev) => {
            if (Array.isArray(prev[field])) {
                const updatedArray = [...prev[field]];
                if (updatedArray[titleIndex] !== undefined) {
                    updatedArray[titleIndex] = value;
                } else {
                    updatedArray.push(value);
                }
                return { ...prev, [field]: updatedArray };
            }
            return { ...prev, [field]: value };
        });
    };

    const handleAddToArrayField = (field, titleIndex, subTitleIndex) => {
        setFormData((prev) => {
            const updatedTitles = [...prev.titles];
            if (titleIndex !== undefined) {
                const updatedSubTitles = [...updatedTitles[titleIndex].subtitles];
                if (subTitleIndex !== undefined) {
                    const updatedLabels = updatedSubTitles[subTitleIndex].labels || [];
                    updatedLabels.push('');
                    updatedSubTitles[subTitleIndex].labels = updatedLabels;
                } else {
                    updatedSubTitles.push({ labels: [] }); 
                }
                updatedTitles[titleIndex].subtitles = updatedSubTitles;
            } else {
                updatedTitles.push({ subtitles: [] }); 
            }
            return { ...prev, titles: updatedTitles };
        });
    };

    const handleRemoveFromArrayField = (field, titleIndex, subTitleIndex, index) => {
        setFormData((prev) => {
            const updatedTitles = [...prev.titles];
            if (titleIndex !== undefined) {
                const updatedSubTitles = [...updatedTitles[titleIndex].subtitles];
                if (subTitleIndex !== undefined) {
                    const updatedLabels = [...updatedSubTitles[subTitleIndex].labels];
                    updatedLabels.splice(index, 1);
                    updatedSubTitles[subTitleIndex].labels = updatedLabels;
                } else {
                    updatedSubTitles.splice(index, 1); 
                }
                updatedTitles[titleIndex].subtitles = updatedSubTitles;
            }
            return { ...prev, titles: updatedTitles };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShipments((prev) => {
            const updatedShipments = { ...prev };
            if (!updatedShipments[formData.model]) {
                updatedShipments[formData.model] = [];
            }
            updatedShipments[formData.model].push({ ...formData, id: updatedShipments[formData.model].length + 1 });
            return updatedShipments;
        });
        setFormData({
            model: '',
            titles: [],
        });
    };

    const columns = [
        { field: 'model', headerName: 'Model', flex: 1 },
        {
            field: 'titles',
            headerName: 'Titles & Subtitles & Labels',
            flex: 3,
            valueGetter: ({ value }) => {
                if (value) {
                    return value.map((title) => {
                        const subtitleAndLabels = title.subtitles.map((subTitle) => {
                            return `${subTitle.subtitle}: ${subTitle.labels.join(', ')}`;
                        }).join(', ');
                        return `${title.title}: ${subtitleAndLabels}`;
                    }).join('; ');
                }
                return '';
            },
        },
    ];

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Incoming Shipment
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    maxWidth: 600,
                    marginBottom: 4,
                }}
            >
                <FormControl fullWidth required disabled={!selectedRegion}>
                    <InputLabel>Model</InputLabel>
                    <Select
                        value={formData.model}
                        onChange={(e) => handleChange('model', null, e.target.value)}
                    >
                        {dropdownOptions.map((option) => (
                            <MenuItem key={option.value} value={option.ModelName}>
                                {option.ModelName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {formData.titles.map((title, titleIndex) => (
                    <Box key={titleIndex}>
                        <Typography variant="subtitle1" gutterBottom>
                            Title {titleIndex + 1}
                        </Typography>
                        <TextField
                            fullWidth
                            value={title.title}
                            onChange={(e) => handleChange('title', titleIndex, e.target.value)}
                            placeholder="Enter title"
                        />
                        {title.subtitles.map((subTitle, subTitleIndex) => (
                            <Box key={subTitleIndex}>
                                <Typography variant="subtitle2" gutterBottom>
                                    SubTitle {subTitleIndex + 1}
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={subTitle.subtitle}
                                    onChange={(e) => handleChange('subTitle', titleIndex, subTitleIndex, e.target.value)}
                                    placeholder="Enter subtitle"
                                />
                                {subTitle.labels.map((label, labelIndex) => (
                                    <Grid container spacing={2} key={labelIndex}>
                                        <Grid item xs={10}>
                                            <TextField
                                                fullWidth
                                                value={label}
                                                onChange={(e) => handleChange('label', titleIndex, subTitleIndex, labelIndex, e.target.value)}
                                                placeholder="Enter label"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton
                                                color="error"
                                                onClick={() => handleRemoveFromArrayField('label', titleIndex, subTitleIndex, labelIndex)}
                                            >
                                                <Remove />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Button
                                    variant="outlined"
                                    startIcon={<Add />}
                                    onClick={() => handleAddToArrayField('label', titleIndex, subTitleIndex)}
                                >
                                    Add Label
                                </Button>
                            </Box>
                        ))}
                        <Button
                            variant="outlined"
                            startIcon={<Add />}
                            onClick={() => handleAddToArrayField('subTitle', titleIndex)}
                        >
                            Add Subtitle
                        </Button>
                    </Box>
                ))}
                <Button
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={() => handleAddToArrayField('title')}
                >
                    Add Title
                </Button>

                <Button variant="contained" color="primary" type="submit">
                    Submit
                </Button>
            </Box>

            <Typography variant="h5" gutterBottom>
                Shipments
            </Typography>

            {Object.keys(shipments).map((model) => (
                <Box key={model} sx={{ marginBottom: 4 }}>
                    <Typography variant="h6">{model}</Typography>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={shipments[model]}
                            columns={columns}
                            pageSize={5}
                            getRowId={(row) => row.id}
                        />
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default IncomingShipmentView;
