import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel } from '@mui/material';
import ErrorDialog from '../ErrorDialog';
import { useUser } from '../../hooks/UserContext';
import { GetTextByRegion } from '../../hooks/Translation';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const MachineModelForm = ({ tabValue, onAdd, onClose }) => {
  const [formData, setFormData] = useState({
    ModelName: '',
  });
  const [global, setGlobal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [error, setError] = useState(null);
  const { selectedRegion } = useUser();
  const tabTypeMap = {
    0: 'machine',
    1: 'rack',
    2: 'tube',
    3: 'fpd',
    4: 'software',
    5: 'monitor',
    6: 'cabinetassembly',
    7: 'barcodeprinter',
    8: 'barcodescanner',
    9: 'option',
    default: 'option',
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.ModelName) errors.ModelName = GetTextByRegion('Model Name is required', selectedRegion);
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const addModel = async () => {
    if (!validateForm()) return; // Don't proceed if validation fails
    if (!global) { //add to single region
      try {
        // const type = tabValue === 0 ? 'machine' : tabValue === 1 ? 'tube' : tabValue === 2 ? 'fpd' : tabValue === 3 ? 'software' : 'option';
        const type = tabTypeMap[tabValue] || tabTypeMap.default;

        const response = await axios.post(`${backendString}/dropdown?selectedRegion=${selectedRegion}&type=${type}`, formData, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          }
        });
        console.log(response.data);
        console.log(tabValue);
        onAdd(response.data, tabValue);
        setFormData({
          ModelName: ''
        });
        onClose(); // Close the dialog after adding the item
      } catch (error) {
        setError(error.response?.data?.error);
      }
    }
    else{ //add to all regions (ignore duplicates)
      const type = tabTypeMap[tabValue] || tabTypeMap.default;

      const response = await axios.post(`${backendString}/dropdown/all?selectedRegion=${selectedRegion}&type=${type}`, formData, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      onAdd(response.data.model, tabValue);
        setFormData({
          ModelName: ''
        });
        onClose(); // Close the dialog after adding the item
    }

  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>{GetTextByRegion("Add Model", selectedRegion)}: {GetTextByRegion(tabTypeMap[tabValue], selectedRegion)}</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} width={300} mt={1}>
            <TextField
              label={GetTextByRegion("Model Name", selectedRegion)}
              name="ModelName"
              value={formData.ModelName}
              onChange={handleInputChange}
              required
              variant="outlined"
              error={!!validationErrors.ModelName && !formData.ModelName}
              helperText={formData.ModelName ? '' : validationErrors.ModelName}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={global}
                  onChange={() => setGlobal(!global)}
                />
              }
              label={GetTextByRegion("Apply for all regions", selectedRegion)}
              style={{ marginLeft: '5px' }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {GetTextByRegion("Cancel", selectedRegion)}
          </Button>
          <Button onClick={addModel} variant="contained" color="primary">
            {GetTextByRegion("Add", selectedRegion)}
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </>
  );
};

export default MachineModelForm;
