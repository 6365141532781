import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, TextField, InputAdornment, IconButton, Dialog, DialogActions, Button } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import axios from 'axios';
import { GetTextByRegion } from '../hooks/Translation';
import { Html5QrcodeScanner } from 'html5-qrcode';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const PartNumberTextField = ({ value, onChange, name, required, formSubmitted, index, disabled, selectedRegion, inhouse = false }) => {
  const [partNumbers, setPartNumbers] = useState([]);
  const [inputValue, setInputValue] = useState(value || '');
  const [error, setError] = useState(false);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const scannerRef = useRef(null); // Reference to store scanner instance

  useEffect(() => {
    const fetchPartNumbers = async () => {
      if (!disabled) {
        try {
          const response = await axios.get(`${backendString}/items?selectedRegion=${selectedRegion === 'MEXICO' ? 'USA' : selectedRegion}`, {
            headers: {
              'Authorization': sessionStorage.getItem('token'),
            },
          });
          const partNumbers = response.data
            .map((item) => item.partnumber)
            .filter((pn) => pn); // Ensure no null or undefined part numbers
          setPartNumbers(partNumbers);
        } catch (error) {
          console.error('Error fetching part numbers:', error);
        }
      }
    };

    fetchPartNumbers();
  }, [selectedRegion, disabled]);

  useEffect(() => {
    setInputValue(value || ''); // Update inputValue if value prop changes
  }, [value]);

  useEffect(() => {
    // Check for error only if form is submitted
    if (formSubmitted && required && !inputValue) {
      setError(true);
    } else {
      setError(false);
    }
  }, [formSubmitted, inputValue, required]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (onChange) {
      onChange(newInputValue);
    }
  };

  const handleCameraClick = () => {
    setIsScannerOpen(true);
  };

  useEffect(() => {
    if (isScannerOpen) {
      setTimeout(() => {
        const scanner = new Html5QrcodeScanner("qr-reader", { fps: 10, qrbox: 250 });
        scanner.render(
          (decodedText) => {
            // Trigger handleChange with the scanned value
            setInputValue(decodedText);
            setIsScannerOpen(false); // Close dialog after scanning
          },
          // (error) => {
          //   console.error("Scanning error:", error);
          // }
        );
        scannerRef.current = scanner;
      }, 100); // Delay to ensure the element is rendered
    }

    // Clean up scanner on close
    return () => {
      if (scannerRef.current) {
        scannerRef.current.clear().catch((error) => console.error('Clear scanner error:', error));
      }
    };
  }, [isScannerOpen]);

  return (
    <>
      <Autocomplete
        freeSolo
        name={name}
        options={partNumbers}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        disabled={disabled}
        onChange={(event, newValue) => {
          const finalValue = newValue || inputValue;
          onChange(finalValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={index < 0
              ? GetTextByRegion('Part Number', selectedRegion)
              : !inhouse
                ? `${GetTextByRegion('Part Number', selectedRegion)} ${index + 1}`
                : `${GetTextByRegion('Item', selectedRegion)} ${index + 1}`
            }
            variant="outlined"
            fullWidth
            required={required}
            error={error}
            helperText={error ? GetTextByRegion('This field is required', selectedRegion) : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {!disabled && (
                    <InputAdornment position="end">
                      <IconButton color="primary" onClick={handleCameraClick}>
                        <QrCodeScannerIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        )}
      />

      <Dialog open={isScannerOpen} onClose={() => setIsScannerOpen(false)} maxWidth="md" fullWidth>
        <div id="qr-reader" style={{ width: '100%', height: '400px' }}></div>
        <DialogActions>
          <Button onClick={() => setIsScannerOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PartNumberTextField;