import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { MenuItem, ListItemText } from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';
const ProfileMenu = ({ onClick, onLogout, isLoggedIn, selectedRegion }) => {

  const backendString = process.env.REACT_APP_BACKEND_STRING;
  const tableNames = ["inventory","machine", "rack", "shipped",
    "onsite", "complete", "inproduction", "stock", "productionShipped", "demo",
    "aftersale", "machineOrder"];
    const retryRequest = async (axiosRequest, retries = 3, delay = 1000) => {
      try {
        return await axiosRequest();
      } catch (error) {
        if (retries > 0 && error.response?.status === 429) {
          console.warn("Rate limit hit. Retrying...");
          await new Promise((resolve) => setTimeout(resolve, delay));
          return retryRequest(axiosRequest, retries - 1, delay * 2); // Double delay each retry
        }
        throw error;
      }
    };
  
  const resetColumns = async () => {
    try {
      for (const tableName of tableNames) {
        // Await the result of the request for each table name
        await retryRequest(() =>
          axios.post(
            `${backendString}/savetableColumns`,
            { tableName, columnString: "" },
            {
              headers: {
                Authorization: sessionStorage.getItem("token"),
              },
            }
          )
        );
        console.log(`Column visibility for table "${tableName}" saved successfully!`);
      }
    } catch (error) {
      console.error("Error saving column visibility:", error.response || error.message);
    }
  };


  return (
    <div>
      <MenuItem
        component={Link}
        onClick={onClick}
        to="/profile"
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          padding: '14px 32px',
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
        }}
      >
        <ListItemText >{GetTextByRegion("Profile", selectedRegion)} </ListItemText>
      </MenuItem>
      {isLoggedIn ? (
        <MenuItem
          onClick={onLogout}
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '14px 32px',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
          }}
        >
          <ListItemText>{GetTextByRegion("Logout", selectedRegion)}</ListItemText>
        </MenuItem>
      ) : (
        <MenuItem
          component={Link}
          to="/login"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '14px 32px',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
          }}
        >
          <ListItemText primary="Login" />
        </MenuItem>
      )}
      <MenuItem
        onClick={resetColumns}
        to=""
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          padding: '14px 32px',
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
        }}

      >
        <ListItemText primary= {GetTextByRegion("Reset Columns",selectedRegion)} />
      </MenuItem>
    </div>
  );
};

export default ProfileMenu;
