import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Box, Autocomplete } from '@mui/material';
import ModelDropdown from '../ModelDropdown';
import axios from 'axios';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import PurchaseOrderColumns from './MachineOrderColumns';

const backendString = process.env.REACT_APP_BACKEND_STRING;
const PurchaseOrder = ({ onOpen, handleAddMachine = null, onClose, type = 'both', isEdit = false, selectedItem, onSave = null }) => {
    const today = new Date().toISOString().split('T')[0]; //get today's date
    const { selectedRegion, user: { username, email } } = useUser();
    const [validationErrors, setValidationErrors] = useState({});

    const [tubes, setTubes] = useState([]);
    const [detectors, setDetectors] = useState([]);
    const [softwares, setSoftwares] = useState([]);
    const [pcs, setPCs] = useState([]);
    const [chinaFinancialReleaseEmail, setChinaFinancialReleaseEmail] = useState("");
    const [monitors, setMonitors] = useState([]);
    const [cabinetAssemblies, setCabinetAssemblies] = useState([]);
    const [barcodeprinters, setBarcodePrinters] = useState([]);
    const [barcodeScanners, setBarcodeScanners] = useState([]);
    const { columnFields, columnLabels } = PurchaseOrderColumns;

    const defaultdata = {
        ponumber: '',
        applicationdate: today,
        customername: '',
        quantity: 1,
        podate: today,
        expecteddeliverydate: '',
        machinemodel: '',
        cabinetassembly: '',
        tube: '',
        tubeSN: '',
        detector: '',
        detectorSN: '',
        pc: '',
        pcSN: '',
        monitor: '',
        monitorSN: '',
        barcodeprinter: '',
        barcodeprinterSN: '',
        barcodescanner: '',
        barcodescannerSN: '',
        software: '',
        softwareID: '',
        labelingmachine: '',
        conveyor: '',
        optbarcodeprinter: '',
        optbarcodeprinterSN: '',
        optbarcodescanner: '',
        optbarcodescannerSN: '',
        optbasetype: '',
        optoperationmode: '',
        inputvoltage: '',
        warranty: '',
        specialrequirement: '',
        machineSN: '',
        actualdeliverydate: '',
        orderStatus: 'NEW',
        owner: username,
        owneremail: email,
        region: selectedRegion,
    }

    const requiredFields = ["ponumber", "customername", "quantity", "applicationdate", "machinemodel"];
    const validateForm = () => {

        const errors = {};
        requiredFields.forEach(field => {
            if (!formData[field]) {
                errors[field] = `this field is required`;
            }
        });
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const [formData, setFormData] = useState(defaultdata)

    useEffect(() => {
        if (isEdit && selectedItem) {
            setFormData(selectedItem);
        }
        getChinaFinancialEmail();
    }, [isEdit, selectedItem]);

    useEffect(() => {
        const fetchData = async (type, setter) => {
            try {
                const response = await axios.get(
                    `${backendString}/dropdown?selectedRegion=${selectedRegion}&type=${type}`,
                    {
                        headers: {
                            Authorization: sessionStorage.getItem("token"),
                        },
                    }
                );
                const models = response.data.map((item) => item.ModelName);
                setter(models);
            } catch (error) {
                console.error(`Error fetching ${type}:`, error);
            }
        };

        fetchData("tube", setTubes);
        fetchData("fpd", setDetectors);
        fetchData("software", setSoftwares);
        fetchData("monitor", setMonitors);
        fetchData("cabinetassembly", setCabinetAssemblies);
        fetchData("barcodeprinter", setBarcodePrinters);
        fetchData("barcodescanner", setBarcodeScanners);
        //...add as many as you can
    }, []);

    const getChinaFinancialEmail = async () => {
        const response = await axios.get(backendString + '/users/email/China QCRelease');
        setChinaFinancialReleaseEmail(response.data.email);
    }

    const handleModelChange = (machineModel) => {
        const fetchData = async (type, setter) => {
            try {
                const response = await axios.get(
                    `${backendString}/dropdown?selectedRegion=${selectedRegion}&type=${type}`,
                    {
                        headers: {
                            Authorization: sessionStorage.getItem("token"),
                        },
                    }
                );
                const models = response.data
                    .filter((item) => {
                        if (item.ForMachine) {
                            const ForMachines = item.ForMachine.split(',');
                            return ForMachines.includes(machineModel); // Check if machineModel exists in ForMachines
                        }
                        return false; // Include items without ForMachine
                    })
                    .map((item) => item.ModelName); // Extract ModelName
                setter(models); // Set the filtered models
            } catch (error) {
                console.error(`Error fetching ${type}:`, error);
            }
        };

        fetchData("tube", setTubes);
        fetchData("fpd", setDetectors);
        fetchData("software", setSoftwares);
        fetchData("monitor", setMonitors);
        fetchData("cabinetassembly", setCabinetAssemblies);
        fetchData("barcodeprinter", setBarcodePrinters);
        fetchData("barcodescanner", setBarcodeScanners);
    }



    const resetFormData = () => {
        setFormData(defaultdata);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        let fieldName = name;
        if (name === "model") {
            fieldName = "machinemodel";
            handleModelChange(value);
        }
        setFormData((prev) => ({ ...prev, [fieldName]: value, }));
        if (validationErrors[name]) {
            setValidationErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };

    const handleSubmit = async () => {

        if (!formData) {
            console.error("Form data is empty");
            return;
        }
        if (!validateForm()) {
            return;
        }
        try {
            const response = await axios.post(
                `${backendString}/machineorder?region=${selectedRegion}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': sessionStorage.getItem('token') || "",
                    },
                }
            );
            //console.log(response.data.data);
            handleAddMachine(response.data.data);
            //send email to china to notify them of new order
            //remind China production and sender
            await axios.post(backendString + `/machineorder/send-email/sent`, {
                "POnumber": formData.ponumber,
                "Email": chinaFinancialReleaseEmail,
            }, {
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                }
            });

            resetFormData();
            onClose();
        } catch (error) {
            console.error("Error submitting machine order:", error);
        }
    };

    //update the record in DB
    const handleEdit = async () => {
        formData.orderStatus = "Edited";
        try {
            var response;
            response = await axios.put(`${backendString}/machineorder/${formData._id}?region=${selectedRegion}`, formData, {
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                }
            });
            onSave(response.data);
            onClose();
        } catch (error) {
        }
    };

    const TextFieldTemplate = (fieldName, headerName, width = 6, row = 1, type = "text") => {
        return (
            <Grid item xs={width}>
                <TextField
                    fullWidth
                    required={requiredFields.includes(fieldName)}
                    error={!!validationErrors[fieldName]}
                    helperText={validationErrors[fieldName] || ""}
                    multiline={row > 1}
                    rows={row > 1 ? row : 1}
                    label={GetTextByRegion(headerName, selectedRegion)}
                    variant="outlined"
                    value={formData[fieldName]}
                    name={fieldName}
                    onChange={handleChange}
                    type={type}
                    InputLabelProps={
                        type === "date" ? { shrink: true } : undefined
                    }
                    disabled={fieldName === "owner" || fieldName === "region" || fieldName === "owneremail"}
                // InputProps={{                   
                //     readOnly: fieldName==="owner",
                // }}
                />
            </Grid>
        );
    }

    const AutoCompleteTemplate = (fieldName, headerName) => {
        var data = [];

        switch (fieldName) {
            case "tube":
                data = tubes;
                break;
            case "detector":
                data = detectors;
                break;
            case "software":
                data = softwares;
                break;
            case "monitor":
                data = monitors;
                break;
            case "cabinetassembly":
                data = cabinetAssemblies;
                break;
            case "barcodeprinter":
                data = barcodeprinters;
                break;
            case "barcodescanner":
                data = barcodeScanners;
                break;
        }
        return (
            <Grid item xs={6}>
                <Autocomplete
                    fullWidth
                    freeSolo
                    options={data}
                    getOptionLabel={(option) => option.label || option}
                    value={formData[fieldName]}
                    onChange={(event, newValue) => {
                        handleChange({
                            target: {
                                name: fieldName,
                                value: newValue || "",
                            },
                        });
                    }}
                    onInputChange={(event, newInputValue) => {
                        handleChange({
                            target: {
                                name: fieldName,
                                value: newInputValue || "",
                            },
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={GetTextByRegion(headerName, selectedRegion)}
                            variant="outlined"
                            name={fieldName}
                        />
                    )}
                />
            </Grid>
        );
    }

    return (
        <Dialog open={onOpen} onClose={onClose}>
            <DialogTitle>{GetTextByRegion("Place a Machine Order", selectedRegion)}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} justifyContent="left" alignItems="center" mt={0}>
                    {columnFields.map((field, index) => {
                        if (field === "orderStatus") { return null; }

                        if (field === "machinemodel") {
                            return (
                                <>
                                    <Grid item xs={12} key={`divider-${field}-${index}`}>
                                        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                                            {GetTextByRegion("Standard", selectedRegion)}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} key={`model-dropdown-${index}`}>
                                        <ModelDropdown
                                            value={formData.machinemodel}
                                            name="machinemodel"
                                            required
                                            onChange={handleChange}
                                            selectedRegion={selectedRegion}
                                            errors={!!validationErrors.model && !formData.machinemodel}
                                            helperText={formData.machinemodel ? '' : validationErrors.model}
                                            type={type}
                                        />
                                    </Grid>
                                </>
                            );
                        } else if (field === "optbarcodeprinter") {
                            return (
                                <>
                                    <Grid item xs={12} key={`divider-${field}-${index}`}>
                                        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                                            {GetTextByRegion("Optional", selectedRegion)}
                                        </Box>
                                    </Grid>
                                    {TextFieldTemplate(field, columnLabels[index])}
                                </>
                            );
                        } else if (field === "inputvoltage") {
                            return (
                                <>
                                    <Grid item xs={12} key={`divider-${field}-${index}`}>
                                        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                                            {GetTextByRegion("Others", selectedRegion)}
                                        </Box>
                                    </Grid>
                                    {TextFieldTemplate(field, columnLabels[index])}
                                </>
                            );
                        } else if (field === "tube" || field === "detector" || field === "software" || field === "monitor" || field === "cabinetassembly" || field === "barcodeprinter" || field === "barcodescanner") {
                            return AutoCompleteTemplate(field, columnLabels[index]);
                        } else if (field === "specialrequirement") {
                            return TextFieldTemplate(field, columnLabels[index], 12, 1);
                        } else if (field === "applicationdate" || field === "expecteddeliverydate" || field === "actualdeliverydate") {
                            return TextFieldTemplate(field, columnLabels[index], 6, null, "date");
                        } else {
                            return TextFieldTemplate(field, columnLabels[index]);
                        }
                    })}
                    <Grid item xs={12} container justifyContent="flex-end">
                        <Button variant="outlined" color="secondary" onClick={() => { onClose(); resetFormData(); }}>
                            {GetTextByRegion("Close", selectedRegion)}
                        </Button>
                        {!isEdit ?
                            (
                                <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginLeft: 8 }}>
                                    {GetTextByRegion("Submit", selectedRegion)}
                                </Button>
                            )
                            :
                            (
                                <Button variant="contained" color="primary" onClick={handleEdit} style={{ marginLeft: 8 }}>
                                    {GetTextByRegion("Edit", selectedRegion)}
                                </Button>
                            )
                        }

                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog>
    );
};

export default PurchaseOrder;
