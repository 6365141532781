import React, { useState, useRef, useEffect } from 'react';
import { TextField, InputAdornment, IconButton, Dialog, DialogActions, Button } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { GetTextByRegion } from '../hooks/Translation';

const SerialBarcodeTextfield = ({ 
  formData, 
  setFormData, 
  validationErrors, 
  selectedRegion 
}) => {
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const scannerRef = useRef(null);

  const handleCameraClick = () => {
    setIsScannerOpen(true);
  };

  // Initialize the scanner only after the dialog has been opened
  useEffect(() => {
    if (isScannerOpen) {
      // Ensure the dialog is rendered before initializing the scanner
      setTimeout(() => {
        const scanner = new Html5QrcodeScanner("qr-reader", { fps: 10, qrbox: 250 });
        scanner.render(
          (decodedText) => {
            setFormData({ ...formData, serial: decodedText });
            setIsScannerOpen(false); // Close dialog after scanning
          },
          (error) => {
            console.error("Scanning error:", error);
          }
        );
        scannerRef.current = scanner;
      }, 100); // Delay to ensure the element is rendered
    }

    return () => {
      if (scannerRef.current) {
        scannerRef.current.clear().catch((error) => console.error('Clear scanner error:', error));
      }
    };
  }, [isScannerOpen, setFormData, formData]);

  return (
    <>
      <TextField
        label={GetTextByRegion("Serial Number", selectedRegion)}
        name="serial"
        required
        value={formData.serial}
        onChange={(e) => setFormData({ ...formData, serial: e.target.value })}
        variant="outlined"
        error={!!validationErrors.serial && !formData.serial}
        helperText={formData.serial ? '' : validationErrors.serial}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton color="primary" onClick={handleCameraClick}>
                <QrCodeScannerIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Dialog open={isScannerOpen} onClose={() => setIsScannerOpen(false)} maxWidth="md" fullWidth>
        <div id="qr-reader" style={{ width: '100%', height: '400px' }}></div>
        <DialogActions>
          <Button onClick={() => setIsScannerOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SerialBarcodeTextfield;
