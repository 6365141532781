import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, Tooltip, InputAdornment, Autocomplete, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GetTextByRegion } from '../../hooks/Translation';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { useUser } from '../../hooks/UserContext';
import ErrorDialog from '../ErrorDialog';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import MobileViewComponent from '../MobileView';
import useWindowSize from '../../hooks/useWindowSize';
import { format } from 'date-fns';
import generateMachineHistoryLogPDF from '../Machines/GenerateMachineHistoryLogPDF';
import roles from '../../hooks/Roles';

const backendString = process.env.REACT_APP_BACKEND_STRING;
const connectionString = backendString + '/machines';

const MachineHistoryLog = ({ machine, selectedRegion, setMachine, fetchMachines }) => {
    const [open, setOpen] = useState(false);
    const { user: { username }, user: { userRights } } = useUser();
    const [history, setHistory] = useState(machine.history || []);
    const [error, setError] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [entryToDelete, setEntryToDelete] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [isScannerOpen, setIsScannerOpen] = useState(false);
    const scannerRef = useRef(null);
    const { windowSize, smallWidth } = useWindowSize();
    const [newEntry, setNewEntry] = useState({
        date: format(new Date(), 'yyyy-MM-dd'),
        job: '',
        partnumber: '',
        notes: '',
        timein: '',
        timeout: '',
        totaltime: '',
        tech: username
    });

    useEffect(() => {
        fetchMachines();
        const sortedHistory = [...history].sort((a, b) => new Date(a.date) - new Date(b.date));
        setHistory(sortedHistory);
    }, [machine.history]);

    const handleOpenDialog = () => {
        setNewEntry({
            date: format(new Date(), 'yyyy-MM-dd'),
            job: '',
            partnumber: '',
            notes: '',
            timein: '',
            timeout: '',
            totaltime: '',
            tech: username
        });
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleCloseErrorDialog = () => {
        setError(null);
    };

    const handleOpenDeleteDialog = (entry) => {
        setOpenDeleteDialog(true);
        setEntryToDelete(entry.row.id);
    };

    const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

    const calculateTotalTime = (timeIn, timeOut) => {
        if (!timeIn || !timeOut) return '';

        const [hoursIn, minutesIn] = timeIn.split(':');
        const [hoursOut, minutesOut] = timeOut.split(':');

        const dateIn = new Date();
        const dateOut = new Date();

        dateIn.setHours(hoursIn, minutesIn);
        dateOut.setHours(hoursOut, minutesOut);

        const differenceInMs = dateOut - dateIn;
        const differenceInMinutes = Math.floor(differenceInMs / 60000);

        const hours = Math.floor(differenceInMinutes / 60);
        const minutes = differenceInMinutes % 60;

        return `${hours}h ${minutes}m`;
    };

    useEffect(() => {
        const totaltime = calculateTotalTime(newEntry.timein, newEntry.timeout);
        setNewEntry((prevEntry) => ({
            ...prevEntry,
            totaltime
        }));
    }, [newEntry.timein, newEntry.timeout]);

    const handleSaveEntry = async () => {
        try {
            // Create a copy of the history and add the new entry
            let updatedHistory = [...history];

            // If there's an entry to delete, remove it from the history
            if (entryToDelete !== null) {
                updatedHistory.splice(entryToDelete, 1);
                setEntryToDelete(null);
                setOpenDeleteDialog(false);

            }
            else if (editIndex !== null) {
                // Update the existing entry
                updatedHistory[editIndex] = newEntry;
                setEditIndex(null); // Clear editIndex after saving
            }
            else {
                // Add the new entry to the history
                updatedHistory = [...updatedHistory, newEntry];
            }

            // Update the machine's history on the backend
            const response = await axios.put(
                `${connectionString}/${machine._id}/history?selectedRegion=${selectedRegion}`,
                updatedHistory,
                {
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                    },
                }
            );

            // Update the local state with the new history
            setHistory(response.data);
            setMachine((prev) => ({ ...prev, history: response.data }));

            // Reset state and close dialogs
            setOpen(false);
            setEditIndex(null);
            setNewEntry({
                date: format(new Date(), 'yyyy-MM-dd'),
                job: '',
                partnumber: '',
                notes: '',
                timein: '',
                timeout: '',
                totaltime: '',
                tech: username
            });
            setEntryToDelete(null); // Reset entryToDelete after save
        } catch (error) {
            setError(GetTextByRegion('An error occurred while saving the entry', selectedRegion));
            console.error('Error saving entry:', error);
        }
    };


    const handleEditEntry = (entry) => {
        setNewEntry({
            date: entry.row.date,
            job: entry.row.job,
            partnumber: entry.row.partnumber,
            notes: entry.row.notes,
            timein: entry.row.timein,
            timeout: entry.row.timeout,
            totaltime: entry.row.totaltime,
            tech: entry.row.tech
        });
        setEditIndex(entry.row.id); // Find the index of the entry to edit
        setOpen(true); // Open the dialog for editing
    };



    const handleCameraClick = () => {
        setIsScannerOpen(true);
    };

    // Initialize the scanner only after the dialog has been opened
    useEffect(() => {
        if (isScannerOpen) {
            // Ensure the dialog is rendered before initializing the scanner
            setTimeout(() => {
                const scanner = new Html5QrcodeScanner("qr-reader", { fps: 10, qrbox: 250 });
                scanner.render(
                    (decodedText) => {
                        setNewEntry({ ...newEntry, notes: decodedText });
                        setIsScannerOpen(false); // Close dialog after scanning
                    },
                    (error) => {
                        console.error("Scanning error:", error);
                    }
                );
                scannerRef.current = scanner;
            }, 100); // Delay to ensure the element is rendered
        }
        return () => {
            if (scannerRef.current) {
                scannerRef.current.clear().catch((error) => console.error('Clear scanner error:', error));
            }
        };
    }, [isScannerOpen, setNewEntry, newEntry]);

    const columns = [
        {
            field: 'date', flex: 1, headerAlign: 'center', align: 'center',
            renderHeader: () => (
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Date', selectedRegion)}<br /></Typography>),
        },
        {
            field: 'job', flex: 2, headerAlign: 'center', align: 'center',
            renderHeader: () => (
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Job Description', selectedRegion)}<br /></Typography>),
        },
        {
            field: 'partnumber', flex: 2, headerAlign: 'center', align: 'center',
            renderHeader: () => (
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Part Number', selectedRegion)}<br /></Typography>),
        },
        {
            field: 'notes', flex: 2, headerAlign: 'center', align: 'center',
            renderHeader: () => (
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Notes/Comment/SN', selectedRegion)}<br /></Typography>),
        },
        {
            field: 'timein', flex: 1, headerAlign: 'center', align: 'center',
            renderHeader: () => (
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Time In', selectedRegion)}<br /></Typography>),
        },
        {
            field: 'timeout', flex: 1, headerAlign: 'center', align: 'center',
            renderHeader: () => (
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Time Out', selectedRegion)}<br /></Typography>),
        },
        {
            field: 'totaltime', flex: 1, headerAlign: 'center', align: 'center',
            renderHeader: () => (
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Total Time', selectedRegion)}<br /></Typography>),
        },
        {
            field: 'tech', flex: 1, headerAlign: 'center', align: 'center',
            renderHeader: () => (
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Technician', selectedRegion)}<br /></Typography>),
        },
        {
            field: 'actions',
            headerName: GetTextByRegion('Actions', selectedRegion),
            width: 100,
            headerAlign: 'center', align: 'center',
            renderHeader: () => (
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Actions', selectedRegion)}<br /></Typography>),
            renderCell: (params) =>
                userRights >= roles['SERVICE'] && (
                    <>

                        <Tooltip title={GetTextByRegion('Edit', selectedRegion)} arrow>
                            <IconButton color="primary" onClick={() => handleEditEntry(params)}>
                                <EditIcon />

                            </IconButton>
                        </Tooltip>
                        <Tooltip title={GetTextByRegion('Delete', selectedRegion)} arrow>
                            <IconButton color="secondary" onClick={() => handleOpenDeleteDialog(params)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )

        }
    ];

    const mobileColumns = [
        { label: GetTextByRegion('Date', selectedRegion), name: "date", type: "date", value: "datr", required: false, InputLabelProps: {}, position: 'left' },
        { label: GetTextByRegion('Job Description', selectedRegion), name: "job", type: "job", value: "job", required: false, InputLabelProps: {}, position: 'left' },
        { label: GetTextByRegion('Part Number', selectedRegion), name: "partnumber", type: "partnumber", value: "partnumber", required: false, InputLabelProps: {}, position: 'left' },
        { label: GetTextByRegion('Notes', selectedRegion), name: "notes", type: "text", value: "notes", required: false, InputLabelProps: {}, position: 'right' },
        { label: GetTextByRegion('Time In', selectedRegion), name: "timein", type: "timein", value: "timein", required: false, InputLabelProps: {}, position: 'right' },
        { label: GetTextByRegion('Time Out', selectedRegion), name: "timeout", type: "timeout", value: "timeout", required: false, InputLabelProps: {}, position: 'right' },
        { label: GetTextByRegion('Total Time', selectedRegion), name: "totaltime", type: "totaltime", value: "totaltime", required: false, InputLabelProps: {}, position: 'right' },
        { label: GetTextByRegion('Technician', selectedRegion), name: "tech", type: "tech", value: "tech", required: false, InputLabelProps: {}, position: 'right' }
    ];

    return (
        <>
            <Box sx={{ height: '600px' }} mt={3}>
                <Box mb={3}>
                    <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                        {GetTextByRegion('Add New Entry', selectedRegion)}
                    </Button>

                    <Button variant='outline' color='primary' onClick={() => { generateMachineHistoryLogPDF(history, machine.serial) }} >
                        Download PDF
                    </Button>
                </Box>
                {windowSize.width > smallWidth ? (
                    <DataGrid
                        rows={history.map((row, index) => ({ ...row, id: index }))}
                        columns={columns}
                    />


                ) : (
                    <MobileViewComponent
                        items={history.map((row, index) => ({ ...row, id: index }))}
                        columnData={mobileColumns}
                        handleEditClick={handleEditEntry}
                        handleDelete={handleOpenDeleteDialog}
                        connectionString={connectionString}
                        selectedRegion={selectedRegion}
                    />
                )}

                <Dialog open={open} onClose={handleCloseDialog}>
                    <DialogTitle>{GetTextByRegion('New History Entry', selectedRegion)}</DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            label={GetTextByRegion('Date', selectedRegion)}
                            fullWidth
                            type='date'
                            value={newEntry.date}
                            onChange={(e) => setNewEntry({ ...newEntry, date: e.target.value })}
                            InputLabelProps={{ shrink: true }}
                        />
                        <Autocomplete
                            freeSolo
                            options={['Uncrate', 'Install Tube', 'Install FPD', 'Install Printer', 'Install PC', 'Install Monitor', 'Incoming Checklist', 'Radiation Survey', '1st Round', '2nd Round', 'Packed', 'Crated']}
                            value={newEntry.job}
                            onInputChange={(event, newValue) => setNewEntry({ ...newEntry, job: newValue })}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    label={GetTextByRegion('Job Description', selectedRegion)}
                                    fullWidth
                                />
                            )}
                        />
                        <TextField
                            margin="dense"
                            label={GetTextByRegion('Part Number', selectedRegion)}
                            fullWidth
                            value={newEntry.partnumber}
                            onChange={(e) => setNewEntry({ ...newEntry, partnumber: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            label={GetTextByRegion('Notes/Comment/SN', selectedRegion)}
                            fullWidth
                            value={newEntry.notes}
                            onChange={(e) => setNewEntry({ ...newEntry, notes: e.target.value })}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={handleCameraClick}>
                                            <QrCodeScannerIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            margin="dense"
                            label={GetTextByRegion('Time In', selectedRegion)}
                            fullWidth
                            type='time'
                            InputLabelProps={{ shrink: true }}
                            value={newEntry.timein}
                            onChange={(e) => setNewEntry({ ...newEntry, timein: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            label={GetTextByRegion('Time Out', selectedRegion)}
                            fullWidth
                            type='time'
                            InputLabelProps={{ shrink: true }}
                            value={newEntry.timeout}
                            onChange={(e) => setNewEntry({ ...newEntry, timeout: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            label={GetTextByRegion('Total Time', selectedRegion)}
                            fullWidth
                            disabled
                            value={newEntry.totaltime}
                            onChange={(e) => setNewEntry({ ...newEntry, totaltime: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            label={GetTextByRegion('Technician', selectedRegion)}
                            fullWidth
                            value={newEntry.tech}
                            onChange={(e) => setNewEntry({ ...newEntry, tech: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="secondary">
                            {GetTextByRegion('Cancel', selectedRegion)}
                        </Button>
                        <Button onClick={handleSaveEntry} color="primary">
                            {GetTextByRegion('Save', selectedRegion)}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>

            <ErrorDialog
                open={!!error}
                onClose={handleCloseErrorDialog}
                errorMessage={error}
            />

            <DeleteConfirmationDialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onConfirm={handleSaveEntry}
                selectedRegion={selectedRegion}
            />

            <Dialog open={isScannerOpen} onClose={() => setIsScannerOpen(false)} maxWidth="md" fullWidth>
                <div id="qr-reader" style={{ width: '100%', height: '400px' }}></div>
                <DialogActions>
                    <Button onClick={() => setIsScannerOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MachineHistoryLog;
