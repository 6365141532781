const columnFields = ['orderStatus','ponumber', 'applicationdate', 'customername', 'quantity', 'podate', 'expecteddeliverydate', 'machinemodel', 'cabinetassembly', 'tube',
    'detector', 'monitor', 'barcodeprinter', 'barcodescanner', 'software', 'labelingmachine', 'conveyor', 'optbarcodeprinter', 'optbarcodescanner',
    'optbasetype', 'optoperationmode', 'inputvoltage', 'warranty', 'specialrequirement', 'region','owner','owneremail'
];

const columnLabels = ['Status','PO Number', 'Application Date', 'Customer Name', 'Quantity', 'PO Date', 'Expected Delivery Date', 'Machine Model', 'Cabinet Assembly', 'Tube',
    'Detector', 'Monitor', 'Barcode Printer', 'Barcode Scanner', 'Software', 'Labeling Machine', 'Conveyor', 'Optional Barcode Printer', 'Optional Barcode Scanner',
    'Optional Base Type', 'Optional Operation Mode', 'Input Voltage', 'Warranty', 'Special Requirement', 'Requested Region',"Request By","Sender Email"
];

export default {columnFields,columnLabels};