import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Table, TableBody, TableCell, TableHead, TableRow, Paper, ToggleButtonGroup, ToggleButton, InputAdornment, IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import axios from 'axios';
import ErrorDialog from '../ErrorDialog';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import { Html5QrcodeScanner } from 'html5-qrcode';
import PartNumberTextField from '../PartNumberTextField';

const backendString = process.env.REACT_APP_BACKEND_STRING;

// Create a styled ToggleButton
const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  backgroundColor: '#D3D3D3',
  color: theme.palette.common.black,
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const InOutPopup = ({ open, onClose, onInbound, onOutbound }) => {
  const theme = useTheme();
  const [mode, setMode] = useState('inbound');
  const [partnumber, setPartnumber] = useState('');
  const [quantity, setQuantity] = useState('1');
  const [shippedTo, setShippedTo] = useState('');
  const [po, setPo] = useState('');
  const [purpose, setPurpose] = useState('');
  const [inboundPartnumbers, setInboundPartnumbers] = useState([]);
  const [outboundPartnumbers, setOutboundPartnumbers] = useState([]);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState({ partnumber: '', quantity: '' });
  const { user, selectedRegion } = useUser();
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const scannerRef = useRef(null);

  const handleCameraClick = () => {
    setIsScannerOpen(true);
  };

  const resetPNandQuantity = () => {
    setPartnumber('');
    setQuantity('1');
  }

  const resetOtherFields = () => {
    setShippedTo('');
    setPo('');
    setPurpose('');
  }

  // Initialize the scanner only after the dialog has been opened
  useEffect(() => {
    if (isScannerOpen) {
      // Ensure the dialog is rendered before initializing the scanner
      setTimeout(() => {
        const scanner = new Html5QrcodeScanner("qr-reader", { fps: 10, qrbox: 250 });
        scanner.render(
          (decodedText) => {
            setPartnumber(decodedText)
            setIsScannerOpen(false); // Close dialog after scanning
          },
          (error) => {
            console.error("Scanning error:", error);
          }
        );
        scannerRef.current = scanner;
      }, 100); // Delay to ensure the element is rendered
    }

    return () => {
      if (scannerRef.current) {
        scannerRef.current.clear().catch((error) => console.error('Clear scanner error:', error));
      }
    };
  }, [isScannerOpen, setPartnumber, partnumber]);


  const fetchOldQuantity = async () => {
    try {
      const response = await axios.get(backendString + `/items/${partnumber}?selectedRegion=${selectedRegion}`);
      const fetchedOldQuantity = response.data.quantity;
      return fetchedOldQuantity;
    } catch (error) {
      setError(error.response?.data?.error);
      return null;
    }
  };

  const validateInputs = () => {
    const errors = {};
    if (!partnumber) errors.partnumber = GetTextByRegion('Part Number is required', selectedRegion);
    if (!quantity || quantity <= 0) errors.quantity = GetTextByRegion('Quantity must be greater than zero', selectedRegion);
    setValidationError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleAddPartnumber = async () => {
    if (!validateInputs()) {
      return;
    }
    const oldQuantity = await fetchOldQuantity();
    if (oldQuantity == null) {
      setError("Part Number not found");
      return;
    }
    const newPartnumber = { partnumber, quantity, oldQuantity };
    try {
      const response = await axios.post(mode === 'inbound' ? backendString + `/items/inbound?selectedRegion=${selectedRegion}` : backendString + `/items/outbound?selectedRegion=${selectedRegion}`, newPartnumber, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      const addedPartnumber = response.data;
      const historyEntry = {
        partnumber,
        action: GetTextByRegion(mode.toUpperCase(), selectedRegion),
        username: user.username,
        quantity,
        shipto: shippedTo,
        ponumber: po,
        purpose,
        timestamp: new Date().toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit', // Ensures month is two digits (e.g., "01" for January)
          day: '2-digit',   // Ensures day is two digits
          hour: '2-digit',  // 2-digit hour
          minute: '2-digit', // 2-digit minute
          hour12: false,    // Use 24-hour time
        }).replace(',', '') // Remove the comma between the date and time
      };

      // Add to history Log
      await axios.post(backendString + `/itemhistories?selectedRegion=${selectedRegion}`, historyEntry, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });

      if (mode === 'inbound') {
        setInboundPartnumbers([...inboundPartnumbers, { ...addedPartnumber, oldQuantity }]);
        if (onInbound) {
          onInbound(addedPartnumber);
        }
      } else {
        setOutboundPartnumbers([...outboundPartnumbers, { ...addedPartnumber, oldQuantity }]);
        if (onOutbound) {
          onOutbound(addedPartnumber);
        }
      }
      resetPNandQuantity();
      resetOtherFields();
    } catch (error) {
      console.error(`Error adding ${mode} partnumber:`, error);
      setError(error.response?.data?.error);
    }
  };

  const handleModeChange = (event, newMode) => {
    if (newMode !== null && newMode !== mode) {
      setMode(newMode);
      resetOtherFields();
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>{mode === 'inbound' ? GetTextByRegion('Inbound Inventory', selectedRegion) : GetTextByRegion('Outbound Inventory', selectedRegion)}</DialogTitle>
        <DialogContent >
          <ToggleButtonGroup
            value={mode}
            exclusive
            onChange={handleModeChange}
            aria-label="inventory mode"
            fullWidth
            sx={{ mb: 1 }}
          >
            <CustomToggleButton value="inbound" aria-label="inbound" theme={theme}>
              {GetTextByRegion("Inbound", selectedRegion)}
            </CustomToggleButton>
            <CustomToggleButton value="outbound" aria-label="outbound" theme={theme}>
              {GetTextByRegion("Outbound", selectedRegion)}
            </CustomToggleButton>
          </ToggleButtonGroup>
          <PartNumberTextField
            value={partnumber || ''}
            onChange={(newValue) => setPartnumber(newValue)}
            required={true}
            selectedRegion={selectedRegion}
            disabled={false}
            formSubmitted={false}
            index={-1}
          />
          <TextField
            label={GetTextByRegion("Quantity", selectedRegion)}
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            fullWidth
            required
            margin="dense"
            type="number"
            error={!!validationError.quantity && quantity <= 0}
            helperText={quantity > 0 ? '' : validationError.quantity}
          />
          <TextField
            label={mode === 'inbound' ? GetTextByRegion("Shipped From", selectedRegion) : GetTextByRegion("Shipped to", selectedRegion)}
            value={shippedTo}
            onChange={(e) => setShippedTo(e.target.value)}
            fullWidth
            margin="dense"
          />
          {(mode !== 'inbound' && (
            <>
              <TextField
                label={GetTextByRegion("PO Number", selectedRegion)}
                value={po}
                onChange={(e) => setPo(e.target.value)}
                fullWidth
                margin="dense"
              />
              <TextField
                label={GetTextByRegion("Purpose", selectedRegion)}
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
                fullWidth
                margin="dense"
              />
            </>
          ))}

          <Button sx={{ mt: 1 }} variant="contained" color="primary" onClick={handleAddPartnumber}>
            {GetTextByRegion(mode, selectedRegion)} {GetTextByRegion("Item", selectedRegion)}
          </Button>
          <Paper style={{ marginTop: 20 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{GetTextByRegion("Part Number", selectedRegion)}</TableCell>
                  <TableCell>{GetTextByRegion("Old Quantity", selectedRegion)}</TableCell>
                  <TableCell>{GetTextByRegion("Updated Quantity", selectedRegion)}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(mode === 'inbound' ? inboundPartnumbers : outboundPartnumbers).map((partnumber, index) => (
                  <TableRow key={index}>
                    <TableCell>{partnumber.partnumber}</TableCell>
                    <TableCell>{partnumber.oldQuantity}</TableCell>
                    <TableCell>{partnumber.quantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {GetTextByRegion("Close", selectedRegion)}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isScannerOpen} onClose={() => setIsScannerOpen(false)} maxWidth="md" fullWidth>
        <div id="qr-reader" style={{ width: '100%', height: '400px' }}></div>
        <DialogActions>
          <Button onClick={() => setIsScannerOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </>
  );
};

export default InOutPopup;
