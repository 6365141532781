import React, { useState } from 'react';
import axios from 'axios';
import { Box, IconButton, Typography, Tooltip, Button } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetTextByRegion } from '../../hooks/Translation';
import roles from '../../hooks/Roles';
import CustomDataGrid from '../CustomDataGrid';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import ErrorDialog from '../ErrorDialog';
import useWindowSize from '../../hooks/useWindowSize';
import calculateColumnWidth from '../../hooks/ColumnWidthCalculator';
import PurchaseOrderColumns from './MachineOrderColumns';
import PurchaseOrderConfirmView from './MachineOrderConfirmView';
import PurchaseOrder from './MachineOrder';

const PurchaseOrderTable = ( {machineOrderColumnVisibility,setPurchaseOrderColumnVisibility,data,setData,userRights,selectedRegion}) => {
    const backendString = process.env.REACT_APP_BACKEND_STRING || '';
    const [selectedItem, setSelectedItem] = useState();
    const [openNewMachineOrderDialog, setOpenNewMachineOrderDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openErrorDialog, setErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { windowSize, smallWidth } = useWindowSize();
    const { columnFields, columnLabels } = PurchaseOrderColumns;

    const commonStyles = { fontSize: '14px', fontWeight: 'bold' };
    const tempColumns = columnFields.map(
        (field, index) => (
            {
                field, flex: 2, headerAlign: 'center', align: 'center', minWidth: calculateColumnWidth(GetTextByRegion(columnLabels[index], selectedRegion)), renderHeader: () => (
                    <Typography sx={commonStyles}>
                        {GetTextByRegion(columnLabels[index], selectedRegion)}
                        <br />
                    </Typography>
                )
            }
        ));
    const columns = [...tempColumns,
    {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        headerAlign: 'center', align: 'center',
        headerClassName: 'rotate-header',
        minWidth: calculateColumnWidth(GetTextByRegion('Actions')),
        renderHeader: () => (
            <Typography sx={commonStyles}>{GetTextByRegion("Actions", selectedRegion)}</Typography>
        ),
        renderCell: (params) => (
            /* Edit and delete Item Dialog */
            userRights >= roles['SALES'] && (
                <Box>
                    <Tooltip title={GetTextByRegion("Edit", selectedRegion)} anrrow>
                        <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(params)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    {userRights >= roles['SALES'] && (
                        <Tooltip title={GetTextByRegion("Delete", selectedRegion)} anrrow>
                            <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(params)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {userRights >= roles['MANAGER'] && (<Tooltip title={GetTextByRegion("Approve", selectedRegion)} anrrow>
                        <IconButton aria-label="approve" color="success" onClick={() => handleConfirmClick(params)}>
                            <AssignmentTurnedInIcon />
                        </IconButton>
                    </Tooltip>
                    )}
                </Box >
            )
        ),
        headerAlign: 'center', // Center header text for Actions column as well   
    },
    ];

    //delete the machine order record
    const handleDelete = (params) => {
        setSelectedItem(params.row);
        setOpenDeleteDialog(true);
    }
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedItem({ _id: '' }); // Reset selectedItem
    };
    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${backendString}/machineorder/${selectedItem._id}?region=${selectedRegion}`, {
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                }
            });
            setData(prevData =>
                prevData.filter(item => item._id !== selectedItem._id)
            );
            setOpenDeleteDialog(false);
        } catch (error) {
            setErrorMessage(error.response?.data?.error || "Error deleting machine order record");
            handleOpenErrorDialog();
        }
    };
    //add a machine order record
    const handleOpenNewMachineOrderDialog = () => {
        setOpenNewMachineOrderDialog(true);
    }
    const handleCloseNewMachineOrderDialog = () => {
        setOpenNewMachineOrderDialog(false);
    }
    //edit the machien order record
    const handleEditClick = (rowData) => {
        handleEdit(rowData);
    }
    const handleEdit = (params) => {
        setSelectedItem(params.row);
        setOpenEditDialog(true);
    };
    const handleEditSave = (modifiedData) => {
        setData(prevData =>
            prevData.map(item =>
                item._id === modifiedData._id ? modifiedData : item
            )
        );
        handleCloseEditDialog();
    };
    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setSelectedItem({ _id: '' }); // Reset selectedItem
    };
    //confirm the machine order
    const handleConfirmClick = (rowData) => {
        handleConfirmDialog(rowData.row);
    }
    const handleConfirmDialog = (params) => {
        setOpenConfirmDialog(true);
        setSelectedItem(params);
    }
    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setSelectedItem({ _id: '' });
    }
    //export excel file
    const handleExport = () => {

    }
    //error dialog
    const handleOpenErrorDialog = () => {
        setErrorDialog(true);
    };
    const handleCloseErrorDialog = () => {
        setErrorDialog(false);
        setErrorMessage('');
    };
    //add now data
    const handleAddMachine = (newMachineOrder) => {
        setData([...data, newMachineOrder]);
    };

    return (
        <div style={{ height: windowSize.height * 0.77 }}>
            <Button variant="contained" color="primary" style={{ margin: 8 }} onClick={() => handleOpenNewMachineOrderDialog()} >
                {GetTextByRegion("New Order", selectedRegion)}
            </Button>

            {openNewMachineOrderDialog && (<PurchaseOrder onOpen={openNewMachineOrderDialog} onClose={handleCloseNewMachineOrderDialog} isEdit={false} handleAddMachine={handleAddMachine} />)}
            
            <CustomDataGrid rows={data} columns={columns} visibilityColumnConfig={machineOrderColumnVisibility} setColumnVisibility={setPurchaseOrderColumnVisibility} tableName={"machineOrder"} />
            
            <Box >
                {openDeleteDialog && (
                    <DeleteConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} onConfirm={handleDeleteConfirm} selectedRegion={selectedRegion} />)
                }
                {openEditDialog && (
                    <PurchaseOrder onOpen={openEditDialog} onClose={handleCloseEditDialog} selectedItem={selectedItem} isEdit={true} onSave={handleEditSave} tabRegion={selectedRegion} />)
                }
                {openConfirmDialog && (
                    <PurchaseOrderConfirmView onOpen={openConfirmDialog} onClose={handleCloseConfirmDialog} selectedItem={selectedItem} onDelete={handleDeleteConfirm} />)
                }
                {openErrorDialog && (
                    <ErrorDialog open={openErrorDialog} onClose={handleCloseErrorDialog} errorMessage={errorMessage} />)
                }
            </Box>
        </div>
    );
};

export default PurchaseOrderTable;
