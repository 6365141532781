import React, { useEffect, useCallback, useRef, useState } from 'react';
import axios from 'axios';
import { useUser, useValidateRegions } from '../hooks/UserContext';
import MachineOrderTable from '../components/MachineOrder/MachineOrderTable';
import roles from '../hooks/Roles';
const validateSelectedRegion = ["USA", "MEXICO", "EU", "TAIWAN", "CHINA"];


const MachineOrderView = () => {
    const prevSelectedRegionRef = useRef();
    const { selectedRegion, refreshUser, user: { username, userRights } } = useUser();
    const backendString = process.env.REACT_APP_BACKEND_STRING;
    const [purchaseOrderColumnVisibility, setPurchaseOrderColumnVisibility] = useState();
 
    const [data, setData] = useState();

    useEffect(() => {
        refreshUser().then(() => {
            const currentRegion = selectedRegion;
            prevSelectedRegionRef.current = currentRegion;        
        }
        )
        const Load = async () => {
            try {
                const columns = await LoadColumns();
                setPurchaseOrderColumnVisibility(columns);
            } catch (error) {
                console.log("error");
            }
        }
        Load();
        
    }, [refreshUser, selectedRegion]);
    useValidateRegions(selectedRegion, validateSelectedRegion);

    //load the user column setting
    const LoadColumns = () => {
        let tableName = "machineOrder";
        return axios
            .get(`${backendString}/loadTableColumns?tableName=${tableName}`, {
                headers: {
                    Authorization: sessionStorage.getItem('token'),
                },
            })
            .then((response) => {
                const columns = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
                return columns;
            })
            .catch((error) => {
                console.error("Error loading columns:", error);
                return {};
            });
    };

    //init columns
    useEffect(() => {
        const Load = async () => {
            try {
                const columns = await LoadColumns();
                setPurchaseOrderColumnVisibility(columns);
            } catch (error) {
                console.log("error");
            }
        }
        Load();
    }, []);


    // Fetch machine order data
    const fetchData = useCallback(async () => {
        try {
            const response = await axios.get(`${backendString}/machineorder?`);          
            //admin can see everthing
            if (userRights >= roles["ADMIN"]) {
                setData(response.data);         
            } else {
                //get only owner's machine order records
                setData(response.data.filter(item => item.owner === username));          
            }
        } catch (error) {
            console.error('Error fetching machine orders:', error);
        }
    }, [backendString,userRights]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            <MachineOrderTable 
            machineOrderColumnVisibility={purchaseOrderColumnVisibility} 
            setPurchaseOrderColumnVisibility={setPurchaseOrderColumnVisibility} 
            data={data} 
            setData={setData}
            username={username}
            userRights={userRights}
            selectedRegion={selectedRegion}
            />
        </div>
    );
};

export default MachineOrderView;
