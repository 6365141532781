import React, { useState, useRef, useEffect } from 'react';
import { TextField, InputAdornment, Paper, Box, IconButton, Dialog, DialogActions, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../hooks/useWindowSize';
import { Html5QrcodeScanner } from 'html5-qrcode';

const SearchBar = ({ searchQuery, setSearchQuery, placeholder }) => {
  const { windowSize, maxWidth } = useWindowSize();
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const scannerRef = useRef(null);

  // Determine the width and height of the Paper component based on window size
  const paperWidth = windowSize.width > maxWidth ? '300px' : '200px';
  const paperHeight = windowSize.width > maxWidth ? '56px' : '40px';

  const handleCameraClick = () => {
    setIsScannerOpen(true);
  };

  const handleClear = () => {
    setSearchQuery(''); // Clear the search query
  };

  useEffect(() => {
    if (isScannerOpen) {
      setTimeout(() => {
        const scanner = new Html5QrcodeScanner("qr-reader", { fps: 10, qrbox: 250 });
        scanner.render(
          (decodedText) => {
            setSearchQuery(decodedText); // Update search query with scanned text
            setIsScannerOpen(false); // Close dialog after scanning
          },
          // (error) => {
          //   console.error("Scanning error:", error);
          // }
        );
        scannerRef.current = scanner;
      }, 100); // Delay to ensure the element is rendered
    }

    return () => {
      if (scannerRef.current) {
        scannerRef.current.clear().catch((error) => console.error('Clear scanner error:', error));
      }
    };
  }, [isScannerOpen, setSearchQuery]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" mb={0}>
      <Paper
        variant="outlined"
        style={{ 
          width: paperWidth, 
          height: paperHeight, 
          margin: '10px 0', 
          display: 'flex', 
          alignItems: 'center' 
        }}
      >
        <TextField
          placeholder={placeholder}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchQuery && (
                  <IconButton onClick={handleClear}>
                    <CloseIcon />
                  </IconButton>
                )}
                <IconButton color="primary" onClick={handleCameraClick}>
                  <QrCodeScannerIcon />
                </IconButton>
              </InputAdornment>
            ),
            style: { 
              padding: '5px 8px',
              height: '100%' // Ensures TextField takes the full height of the Paper
            }
          }}
          variant="outlined"
          style={{ height: '100%' }} // Ensures TextField takes the full height of the Paper
        />
      </Paper>

      <Dialog open={isScannerOpen} onClose={() => setIsScannerOpen(false)} maxWidth="md" fullWidth>
        <div id="qr-reader" style={{ width: '100%', height: '400px' }}></div>
        <DialogActions>
          <Button onClick={() => setIsScannerOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SearchBar;
