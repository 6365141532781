import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import TechnicianDropdown from './TechnicianDropdown';
import ErrorDialog from '../ErrorDialog';
import { useTheme } from '@mui/material/styles';
import { useUser } from '../../hooks/UserContext';
import { GetTextByRegion } from '../../hooks/Translation';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const EventForm = ({ onClose, onAdd }) => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    title: '',
    customer: '',
    serial: '',
    model: [], // Ensure model is initialized as an array
    address: '',
    serviceType: '',
    description: '',
    technicians: '',
    addTechnicians: '',
  });

  const [models, setModels] = useState([]);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const {selectedRegion } = useUser();

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response1 = await axios.get(`${backendString}/dropdown?selectedRegion=${selectedRegion}&type=machine`);
        const response2 = await axios.get(`${backendString}/dropdown?selectedRegion=${selectedRegion}&type=rack`);
        setModels([...response1.data, ...response2.data]); // Adjust based on your backend response structure
      } catch (error) {
        console.error('Error fetching machine models:', error);
        setError(error.response?.data?.error);
      }
    };

    fetchModels();
  }, [selectedRegion]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleModelChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      model: value, // Since `value` is already handled as an array by Select component
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.startDate) newErrors.startDate = 'Start Date is required';
    if (!formData.endDate) newErrors.endDate = 'End Date is required';
    if (!formData.startTime) newErrors.startTime = 'Start Time is required';
    if (!formData.endTime) newErrors.endTime = 'End Time is required';
    if (!formData.title) newErrors.title = 'Title is required';
    if (!formData.customer) newErrors.customer = 'Customer is required';
    if (!formData.serial) newErrors.serial = 'Serial is required';
    if (formData.model.length === 0) newErrors.model = 'Model(s) is/are required';
    if (!formData.address) newErrors.address = 'Address is required';
    if (!formData.serviceType) newErrors.serviceType = 'Service Type is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return; // Prevent submission if validation fails
    }   
    let connectionString = '';
    try {
      connectionString = backendString + '/events';
      const response = await axios.post(`${connectionString}?selectedRegion=${selectedRegion}`, formData, {headers: {
        'Authorization': sessionStorage.getItem('token')
      }
    });
      onAdd(response.data);
      setFormData({
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        title: '',
        customer: '',
        serial: '',
        model: [], // Reset model to empty array after submission
        address: '',
        serviceType: '',
        description: '',
        technicians: '',
        addTechnicians: '',
      });
      onClose(); // Close the dialog after adding the event
    } catch (error) {
      console.error('Error creating event:', error);
      setError(error.response?.data?.error);
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{GetTextByRegion("Add New Event",selectedRegion)}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '20px',
            borderRadius: '8px',
            width: '300px',
            height: 'auto',
          }}
        >
          <TextField
            label={GetTextByRegion("Start Date",selectedRegion)}
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            required
            error={!!errors.startDate && !formData.startDate}
            helperText={formData.startDate ? '' : errors.startDate}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              style: { color: !formData.startDate && errors.startDate ? theme.palette.error.main : 'inherit' }
            }}
          />
          <TextField
            label={GetTextByRegion("End Date",selectedRegion)}
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            required
            error={!!errors.endDate && !formData.endDate}
            helperText={formData.endDate ? '' : errors.endDate}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              style: { color: !formData.endDate && errors.endDate ? theme.palette.error.main : 'inherit' }
            }}
          />
          <TextField
            label={GetTextByRegion("Start Time",selectedRegion)}
            type="time"
            name="startTime"
            value={formData.startTime}
            onChange={handleChange}
            required
            error={!!errors.startTime && !formData.startTime}
            helperText={formData.startTime ? '' : errors.startTime}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              style: { color: !formData.startTime && errors.startTime ? theme.palette.error.main : 'inherit' }
            }}
          />
          <TextField
            label={GetTextByRegion("End Time",selectedRegion)}
            type="time"
            name="endTime"
            value={formData.endTime}
            onChange={handleChange}
            required
            error={!!errors.endTime && !formData.endTime}
            helperText={formData.endTime ? '' : errors.endTime}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              style: { color: !formData.endTime && errors.endTime ? theme.palette.error.main : 'inherit' }
            }}
          />
          <TextField
            label={GetTextByRegion("Title",selectedRegion)}
            variant="outlined"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
            error={!!errors.title && !formData.title}
            helperText={formData.title ? '' : errors.title}
          />
          <TextField
            label={GetTextByRegion("Customer",selectedRegion)}
            variant="outlined"
            name="customer"
            value={formData.customer}
            onChange={handleChange}
            required
            error={!!errors.customer && !formData.customer}
            helperText={formData.customer ? '' : errors.customer}
          />
          <TextField
            label={GetTextByRegion("Serial Number",selectedRegion)}
            variant="outlined"
            name="serial"
            value={formData.serial}
            onChange={handleChange}
            required
            error={!!errors.serial && !formData.serial}
            helperText={formData.serial ? '' : errors.serial}
          />
          <FormControl fullWidth required error={!!errors.model && formData.model.length === 0}>
            <InputLabel>{GetTextByRegion("Model",selectedRegion)}</InputLabel>
            <Select
              label="Models"
              name="model"
              value={formData.model}
              onChange={handleModelChange}
              multiple
            >
              {models
              .slice()
              .sort((a, b) => a.ModelName.localeCompare(b.ModelName))
              .map((model) => (
                <MenuItem key={model._id} value={model.ModelName}>
                  {model.ModelName}
                </MenuItem>
              ))}
            </Select>
            {errors.model && formData.model.length === 0 && (
              <FormHelperText>{errors.model}</FormHelperText>
            )}
          </FormControl>
          <TextField
            label={GetTextByRegion("Address",selectedRegion)}
            variant="outlined"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
            error={!!errors.address && !formData.address}
            helperText={formData.address ? '' : errors.address}
          />
          <FormControl required error={!!errors.serviceType && !formData.serviceType}>
            <InputLabel htmlFor="serviceType">
              {GetTextByRegion("Type of Service",selectedRegion)}
            </InputLabel>
            <Select
              label="Type of Service"
              name="serviceType"
              value={formData.serviceType}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Type of Service' }}
            >
              <MenuItem value="PM 1 Year">{GetTextByRegion("1 Year PM",selectedRegion)}</MenuItem>
              <MenuItem value="PM 3 Year">{GetTextByRegion("3 Year PM",selectedRegion)}</MenuItem>
              <MenuItem value="Installation">{GetTextByRegion("Installation",selectedRegion)}</MenuItem>
              <MenuItem value="Software">{GetTextByRegion("Software",selectedRegion)}</MenuItem>
              <MenuItem value="Repair">{GetTextByRegion("Repair",selectedRegion)}</MenuItem>
              <MenuItem value="Other">{GetTextByRegion("Other",selectedRegion)}</MenuItem>
            </Select>
            {errors.serviceType && !formData.serviceType && (
              <FormHelperText>{errors.serviceType}</FormHelperText>
            )}
          </FormControl>
          <TextField
            label={GetTextByRegion("Description",selectedRegion)}
            variant="outlined"
            name="description"
            value={formData.description}
            onChange={handleChange}
            multiline
            rows={4}
          />
          <TechnicianDropdown 
            label={GetTextByRegion("Technician",selectedRegion)} 
            name="technicians"
            formData={formData.technicians} 
            handleChange={handleChange} 
          />
          <TechnicianDropdown 
            label={GetTextByRegion("Additional Technician",selectedRegion)}
            name="addTechnicians"
            formData={formData.addTechnicians} 
            handleChange={handleChange} 
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {GetTextByRegion("Cancel",selectedRegion)}
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {GetTextByRegion("Add Event",selectedRegion)}
        </Button>
      </DialogActions>
    </Dialog>
    <ErrorDialog
     open={!!error}
     onClose={handleCloseErrorDialog}
     errorMessage={error}
    />
    </>
  );
};

export default EventForm;
